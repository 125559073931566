import {getList} from '../../api/HomeNotice'


export const state = {
    aaa:66
}

export const actions = {
    GetList(_,data) {
        return getList(data)
    }
}
export const mutations = {

}