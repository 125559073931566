import {getList,getdesc,toneedadd,conteact,selfReleasedList,conteacts,updateEntTime,selfContactedList} from '../../api/Need'


export const state = {
    // aaa:66
}

export const actions = {
    GetList(_,data) {
        return getList(data)
    },
    GetDesc(_,data) {
        return getdesc(data)
    },
    // 添加需求
    needadd(_,data) {
        return toneedadd(data)
    },
    // 获取联系方式的时候 调取的接口
    Conteact(_,data) {
        return conteact(data)
    },
    // 获取我的需求的接口  我发布的
    SelfReleasedList(_,data) {
        return selfReleasedList(data)
    },
    // 这里是获取联系列表
    Conteacts(_,data) {
        return conteacts(data)
    },
    // 这个是延期的接口
    UpdateEntTime(_,data) {
        return updateEntTime(data)
    },
    // 这里是我的需求的接口  我联系的
    SelfContactedList(_,data) {
        return selfContactedList(data)
    }


}
export const mutations = {

}