import http from '../utils/http'
import api from '.'

export function getList(data) {
    return http.get(api.Need.GetList,data)
}
export function getdesc(data) {
    return http.get(api.Need.GetDesc,data)
}
export function toneedadd(data) {
    return http.post(api.Need.add,data)
}
export function conteact(data) {
    return http.get(api.Need.Conteact + '/' + data.id + '/'+'Conteact')
}
export function selfReleasedList(data) {
    // console.log(data)
    // return http.get(api.Need.SelfReleasedList + '?pageIndex=1&pageSize=6')
    return http.get(api.Need.SelfReleasedList,data)
}
export function conteacts(data) {
    return http.get('api/need/'+ data.id +'/Conteacts')
}
export function updateEntTime(data) {
    // https://zeiot.vip/api/need/UpdateEntTime/353?etime=2023-07-12

    // console.log(data,'照上面的格式走')
    return http.get('api/need/UpdateEntTime'+ '/' + data.id + '?etime=' + data.time)
}
export function selfContactedList(data) {
    return http.get(api.Need.SelfContactedList,data)
}