import http from '../utils/http'
import api from '.'

export function secchekc(data) {
    return http.post(api.Image.SecChekc,data)
}
export function uploadlocal(data) {

    return http.post(api.Image.UploadLocal,data)
}
export function upload(data) {
    return http.post(api.Image.Upload, data)
}
