import {getList,AllNum,getmodule,create,authcompany,AUthcompanyreason} from '../../api/Company'


export const state = {
    // aaa:66
}

export const actions = {
    GetList(_,data) {
        return getList(data)
    },
    allnum(_,data) {
        return AllNum(data)
    },
    GetModule(_,data) {
        return getmodule(data)
    },
    createcompany(_,data) {
        return create(data)
    },
    // 企业认证
    createauthcompany(_,data) {
        return authcompany(data)
    },
    // 拿到企业审核失败的原因
    authcompanyreason(_,data) {
        return AUthcompanyreason(data)
    }

}
export const mutations = {

}