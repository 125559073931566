export default {
    Admin: {
        wxWebLogin:'api/Admin/wxWebLogin',
        getnotice:'api/homenotice/GetList'
    },
    HomeNotice: {
        GetList: 'api/HomeNotice/GetList'
    },
    Article: {
        GetList: 'api/Article/GetList',
        GetTypeSelect : 'api/Article/GetTypeSelect',
        GetTypeList : 'api/Article/GetTypeList',
        Get:'api/Article/Get',
        GetContent: 'api/Article/GetContent',
        add:'api/Article/Add'

    },
    Recommender: {
        MiniGetListHome: 'api/Recommender/MiniGetListHome',
        MiniGetListClass: 'api/Recommender/MiniGetListClass'
    },
    Need: {
        GetList:'api/Need/GetList',
        GetDesc:'api/Need/GetDesc',
        add:'api/Need/Add',
        Conteact:'api/need',
        SelfReleasedList:'api/Need/SelfReleasedList',
        SelfContactedList:'api/Need/SelfContactedList'
    },
    Company: {
        GetCompanys:'api/Company/GetCompanys',
        allnum:'api/user/company/allnum',
        Module:'api/Company/Site/Module/',
        createcompany:'api/wxuser/createcompany',
        createauthcompany:'api/wxuser/createauthcompany',
        authcompanyreason:'api/wxuser/authcompanyreason'
    },
    GoodType: {
        GetList:'api/GoodType/GetList'
    },
    Source: {
        GetPublicTrades: 'daili/api/Source/GetPublicTrades',
        GetHomeList:'daili/api/Source/GetHomeList',
        GetPublicInfo:'daili/api/Source/GetPublicInfo',
        getloginimg:'/api/wxuser/GetUserLoginEwm',
        getloginimgxinxi:'/api/wxuser/GetLoginEwmResult'
    },
    searchkey: {
        searchkey:'api/searchkey'
    },
    Good: {
        miniGetList: 'api/Good/miniGetList',
        miniGetInfo: 'api/Good/miniGetInfo',
        GetImgsById:'api/Good/GetImgsById'
    },
    Wxuser: {
        GetShow: 'api/wxuser/GetShow',
        GetAdmins: 'api/wxuser/GetAdmins',
        GetMySelf: 'api/wxuser/GetMySelf',
        GetCompanyInfo: 'api/wxuser/GetCompanyInfo',
        createauthcompany:'api/wxuser/createauthcompany',
        GetMyShow:'api/wxuser/GetMyShow',
        authcompany:'api/wxuser/authcompany'
    },
    vip: {
        Company_Pay:'/api/Vip/Company_Pay'
    },
    Image: {
        SecChekc:'/api/Image/SecChekc',
        UploadLocal:'/api/Image/UploadLocal',
        Upload:'/api/Image/Upload'

    }



}