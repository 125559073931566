import {secchekc,uploadlocal,upload} from '../../api/Image'


export const state = {
    // aaa:66
}

export const actions = {
    SecChekc(_,data) {
        return secchekc(data)
    },
    UploadLocal(_,data) {
        return uploadlocal(data)
    },
    Upload(_,data) {
        return upload(data)
    }

}
export const mutations = {

}