import {getList,GetHomeList,getpublicinfo,getimg,Getloginimgxinxi} from '../../api/Source'


export const state = {
    // aaa:66
}

export const actions = {
    GetList(_,data) {
        return getList(data)
    },
    GetHomelist(_,data) {
        return GetHomeList(data)
    },
    GetPublicInfo(_,data) {
        return getpublicinfo(data)
    },
    getloginimg(_,data) {
        return getimg(data)
    },
    getloginimgxinxi(_,data) {
        return Getloginimgxinxi(data)
    }
}
export const mutations = {

}