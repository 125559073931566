import {miniGetListHome,minigetlistclass} from '../../api/Recommender'


export const state = {
    // aaa:66
}

export const actions = {
    MiniGetListHome(_,data) {
        return miniGetListHome(data)
    },
    MiniGetListClass(_,data) {
        return minigetlistclass(data)
    }

}
export const mutations = {

}