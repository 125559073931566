import {wxweblogin,Getnotice} from '../../api/Admin'


export const state = {
    // aaa:66
}

export const actions = {
    wxWebLogin(_,data) {
        return wxweblogin(data)
    },
    getnotice(_,data) {
        return Getnotice(data)
    }
}
export const mutations = {

}