import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView-new.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {path:'/', redirect:'/home' },
      {
        path:'/home',
        name: 'homepage',
        component: () => import(/* webpackChunkName: "about" */ '../views/home-new/index.vue')  
      },
      {
        path:'/vipcenter',
        name:'vipcenter',
        // component: () => import(/* webpackChunkName: "about" */ '../views/zixunku/index.vue')
        component: resolve => require(['../views/vipcenter/index.vue'], resolve)
      },
    ]
  },
  // {
  //   path: '/home',
  //   name: 'homepage',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/HomeView-new.vue')
  // },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/login.vue')  
  },
  {
    path: '/buypage',
    name: 'buypage',
    component: () => import(/* webpackChunkName: "about" */ '../views/buypage/index.vue')  
  },
  {
    path: '/gerenguanli',
    name: 'gerenguanli',
    component: () => import(/* webpackChunkName: "about" */ '../views/gerenguanli/index.vue')  
  },

  {
    path: '/fabu',
    name: 'fabu',
    component: () => import(/* webpackChunkName: "about" */ '../views/fabu/index.vue')  
  },
  {
    path: '/gerenfabu',
    name: 'gerenfabu',
    component: () => import(/* webpackChunkName: "about" */ '../views/gerenfabupage/index.vue')  
  },
  {
    path: '/gerenfabuxuqiu',
    name: 'gerenfabuxuqiu',
    component: () => import(/* webpackChunkName: "about" */ '../views/gerefabuxuqiu/index.vue')  
  },
  {
    path: '/qiyerenzheng',
    name: 'qiyerenzheng',
    component: () => import(/* webpackChunkName: "about" */ '../views/fabuqiyerenzheng/index.vue')  
  },
  {
    path: '/anewqiye',
    name: 'anewqiye',
    component: () => import(/* webpackChunkName: "about" */ '../views/anewqiye/index.vue')  
  },
  {
    path: '/qiyerenzhengnew',
    name: 'qiyerenzhengnew',
    component: () => import(/* webpackChunkName: "about" */ '../views/qiyerenzheng/index.vue')  
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/gotosearch/AboutView.vue'),
    children:[
      // {
      //   path: '/home',
      //   name: 'homepage',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "about" */ '../views/homepage/index.vue')
      // },
      {
        path:'/product',
        name:'productpage',
        component: () => import(/* webpackChunkName: "about" */ '../components/modal-chanpin.vue')
      },
      {
        path:'/need',
        name:'needpage',
        component: () => import(/* webpackChunkName: "about" */ '../components/modal-xuqiu.vue')
      },
      {
        path:'/scheme',
        name:'schemepage',
        component: () => import(/* webpackChunkName: "about" */ '../components/modal-fangan.vue')
      },
      {
        path:'/company',
        name:'companypage',
        component: () => import(/* webpackChunkName: "about" */ '../components/modal-qiye.vue')
      },
      {
        path:'/information',
        name:'informationpage',
        // component: () => import(/* webpackChunkName: "about" */ '../views/zixunku/index.vue')
        component: resolve => require(['../views/zixunku/index.vue'], resolve)
      },
      {
        path:'/resource',
        name:'resourcepage',
        // component: () => import(/* webpackChunkName: "about" */ '../views/zixunku/index.vue')
        component: resolve => require(['../components/modal-ziyuan.vue'], resolve)
      },

      {
        path:'/resource/detail/:id',
        name:'resourcedetail',
        component: resolve => require(['../views/resourcedetail/index.vue'], resolve)

      },
      {
        path:'/company/detail/:id',
        name:'conmpanydetail',
        component: resolve => require(['../views/companydetail/index.vue'], resolve)

      },
      {
        path:'/product/detail/:id',
        name:'productdetail',
        component: resolve => require(['../views/productdetail/index.vue'], resolve)

      },
      {
        path:'/need/detail/:id',
        name:'needdetail',
        component: resolve => require(['../views/xuqiudetail/index.vue'], resolve)

      },
      {
        path:'/scheme/detail/:id',
        name:'schemedetail',
        component: resolve => require(['../views/schemedetail/index.vue'], resolve)

      },
      {
        path:'/information/detail/:id',
        name:'informationpagedetail',
        // component: () => import(/* webpackChunkName: "about" */ '../views/zixunku/index.vue')
        component: resolve => require(['../views/schemedetailcopy/index.vue'], resolve)
      },

    ]
  },
  {
    path: '/newabout',
    name: 'newabout',
    component: () => import(/* webpackChunkName: "about" */ '../views/new-limian/index.vue'),
    children:[
      {
        path:'/newscheme',
        name:'newschemepage',
        component: () => import(/* webpackChunkName: "about" */ '../components/mdoal-newfangan.vue')
      },

    ]  
  }

]

let originPush =  VueRouter.prototype.push;
VueRouter.prototype.push = function (location, resolve, reject){
  if (resolve && reject) {    //如果传了回调函数，直接使用
      originPush.call(this, location, resolve, reject);
  }else {                     //如果没有传回调函数，手动添加
      originPush.call(this, location, ()=>{}, ()=>{}); 
  }
}


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.afterEach(() => {
  window.scrollTo(0,0)
})

export default router
