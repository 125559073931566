import http from '../utils/http'
import api from '.'

export function getshow(data) {
    // console.log(data,'看看携带')
    return http.get(api.Wxuser.GetShow,data)
}
export function getadmins(data) {
    const id = data.e
    // console.log(data,'先看看带了什么')
    delete data.e
    return http.get(api.Wxuser.GetAdmins + '/' + id,data)
}
export function getmyself(data) {
    return http.get(api.Wxuser.GetMySelf,data)
}
export function getcompanyinfo(data) {
    return http.get(api.Wxuser.GetCompanyInfo,data)
}
export function CReateauthcompany(data) {
    const id = data.unionid
    delete data.unionid

    return http.post(api.Wxuser.createauthcompany + '?' + 'unionid=' + id,data)
}
export function getmyshow(data) {
    return http.get(api.Wxuser.GetMyShow,data)
}
export function AuthCompany(data) {
    return http.post(api.Wxuser.authcompany,data)
}