import http from '../utils/http'
import api from '.'

export function getList(data) {
    return http.get(api.Company.GetCompanys,data)
}
export function AllNum(data) {
    return http.get(api.Company.allnum,data)
}
export function getmodule(data) {
    // console.log(data,'看看携带的是什么')
    return http.get(api.Company.Module + '/' + data.company_id)
}
export function create(data) {
    let unid = data.unionid
    delete data.unionid


    return http.post(api.Company.createcompany + '?' +'unionid=' +unid, data)
}
export function authcompany(data) {
    let unid = data.unionid
    delete data.unionid
    return http.post(api.Company.createauthcompany+ '?' +'unionid=' +unid,data)
}
// GetApplyReason: url + 'api/wxuser/authcompanyreason',
export function AUthcompanyreason(data) {
    return http.get(api.Company.authcompanyreason,data)
}



