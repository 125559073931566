import {getList,gettypeselect,getTypeList,get,getContent,recommendss,add,IDgetlist,del} from '../../api/Article'


export const state = {
    // aaa:66
}

export const actions = {
    GetList(_,data) {
        return getList(data)
    },
    GetTypeSelect(_,data) {
        return gettypeselect(data)
    },
    GetTypeList(_,data) {
        return getTypeList(data)
    },
    Get(_,data) {
        return get(data)
    },
    GetContent(_,data) {
        return getContent(data)
    },
    recommends(_,data) {
        return recommendss(data)
    },
    Add(_,data) {
        return add(data)
    },
    idgetlist(_,data) {
        return IDgetlist(data)
    },
    // 我的咨询里面的删除内容
    Del(_,data) {
        return del(data)
    }
    }
export const mutations = {

}