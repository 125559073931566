import {getshow,getadmins,getmyself,getcompanyinfo,CReateauthcompany,getmyshow,AuthCompany} from '../../api/Wxuser'


export const state = {
    // aaa:66
}

export const actions = {
    GetShow(_,data) {
        return getshow(data)
    },
    GetAdmins(_,data) {
        return getadmins(data)
    },
    GetMySelf(_,data) {
        return getmyself(data)
    },
    GetCompanyInfo(_,data) {
        return getcompanyinfo(data)
    },
    // 个人方面企业认证
    createauthcompany(_,data) {
        return CReateauthcompany(data)
    },
    GetMyShow(_,data) {
        return getmyshow(data)
    },
    // 企业身份认证
    authcompany(_,data) {
        return AuthCompany(data)
    }

}
export const mutations = {

}