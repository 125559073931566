import {getList,minigetInfo,getimgsbyid} from '../../api/Good'


export const state = {
    // aaa:66
}

export const actions = {
    GetList(_,data) {
        return getList(data)
    },
    miniGetInfo(_,data) {
        return minigetInfo(data)
    },
    GetImgsById(_,data) {
        return getimgsbyid(data)
    }
}
export const mutations = {

}