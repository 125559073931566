import http from '../utils/http'
import api from '.'

export function getList(data) {
    return http.get(api.Article.GetList, data)
}
export function gettypeselect(data) {
    return http.get(api.Article.GetTypeSelect, data)
}
export function getTypeList(data) {
    return http.get(api.Article.GetTypeList, data)
}
export function get(data) {
    return http.get(api.Article.Get + '/' + data.id)
}
export function getContent(data) {
    return http.get(api.Article.GetContent + '/' + data.id)
}
export function recommendss(data) {
    return http.get('api/Article/' + data.id+ '/' +'recommends',data)
}
export function add(data) {
    return http.post(api.Article.add, data)
}
export function IDgetlist(data) {
    let id = data.id
    delete data.id
    return http.get('api/Article/' + id + '/GetList',data)
}
export function del(data) {
    return http.delete('api/Article/Del' + '/' + data.id)
}