import http from '../utils/http'
import api from '.'

export function getList(data) {
    return http.get(api.Source.GetPublicTrades,data)
}
export function GetHomeList(data) {
    return http.get(api.Source.GetHomeList,data)
}
export function getpublicinfo(data) {
    return http.get(api.Source.GetPublicInfo,data)
}
export function getimg(data) {
    return http.get(api.Source.getloginimg,data)
}
export function Getloginimgxinxi(data) {

    return http.get(api.Source.getloginimgxinxi + '?key='+data.key)
}