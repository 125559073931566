<template>
    <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" class="dislogwidth">
      <!-- <span>{{content}}</span> -->
      <div class="title">请前往微信小程序进行体验！</div>
      <img class="logo" :src="require('../assets/xiaochengxu.jpg')" alt="解析失败" />

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="visible = false">{{cancelText}}</el-button> -->
        <el-button type="primary" @click="ok">{{okText}}</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  export default {
    name: 'MyDialog',
    props: {
      title: String,
      content: String,
      cancelText: {
        type: String,
        default: '取消'
      },
      okText: {
        type: String,
        default: '确定'
      }
    },
    data() {
      return {
        visible: false
      }
    },
    methods: {
      ok() {
        this.$emit('ok')
        this.visible = false
      }
    }
  }
  </script>
<style lang="scss" scoped>
.dislogwidth {
    width: 50%;
    margin: 0 auto;

}
.title {
    padding: 10px;
    font-size: 18px;
    text-align: center;
}
.logo {
    width: 200px;
    height: 200px;
    // text-align:center
    position: relative;
    left: 50%;
    margin-left: -100px;
}
// .dislogwidth {
//      display: flex;
//      flex-direction: column;
//      margin:0 !important;
//      position:absolute;
//      top:50%;
//      left:50%;
//      transform:translate(-50%,-50%);
//      /*height:600px;*/
//      max-height:calc(100% - 200px);
//      max-width:calc(100% - 30px);
// }
// .el-dialog__wrapper {
//     margin-top: 0 !important;
//     top: 50%;
//     transform: translateY(-50%)
// }
.el-dialog__body{
    padding: 0 18px;
}


</style>
