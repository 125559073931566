<template>
    <div id="app">
      <!-- <router-view></router-view> -->
      <div class="tou">
        <div class="shuqian">
          <span class="xiaochengxu" @click="toxiaochengxu"><i class="iconfont icon-xiaochengxu"></i><span class="xiaochengxuhou">小程序</span> </span>
          <span class="mianfeifabu" @click="tofabu" v-if="!sysuser || sysuser.auth_company == null"><i class="iconfont icon-fabu"></i><span class="mianfeifabuhou">创建企业</span> </span>


          <span class="mianfeifabu" @click="torenzheng" v-if="sysuser && sysuser.auth_company &&sysuser.auth_company !== null &&!sysuser.auth_company.examine"><i class="iconfont icon-fabu"></i><span class="mianfeifabuhou">认证企业</span> </span>
          
          <span class="mianfeifabu" @click="tomyqiye" v-if="sysuser &&sysuser.auth_company &&sysuser.auth_company !== null&& sysuser.auth_company.examine"><i class="iconfont icon-fabu"></i><span class="mianfeifabuhou">我的企业</span> </span>
          <!-- <span class="mianfeifabu" @click="tomyqiye"><i class="iconfont icon-fabu"></i><span class="mianfeifabuhou">我的企业</span> </span> -->

          <span class="mianfeifabu" @click="tohuiyuancenter"><i class="iconfont icon-huiyuan2"></i><span class="mianfeifabuhou">会员中心</span> </span>


          <span @click="ToLogin" class="denglu"><i class="iconfont icon-denglu"></i><span class="dengluhou">{{sysuser ? sysuser.auth_persional.tel : '登录'}} </span> </span>


          <span class="mianfeifabu" v-if="!!sysuser" @click="tologinout"><i class="iconfont icon-tuichu"></i><span class="mianfeifabuhou">退出登录</span> </span>



          <!-- <el-button @click="dealshoujihao"></el-button> -->
        </div>



        <div class="toulogo" style="position:relative">


          <img class="logo" :src="require('../assets/logo.png')" alt="解析失败" />
              <el-input
                
                class="inputofelementui"
                placeholder="请输入搜索内容"
                v-model="input"
                style="width:55%;margin-top: 100px;"
                clearable>
                <el-select v-model="select" slot="prepend" placeholder="请选择" class="elselect"
                >
                  <el-option label="资源" value="1"></el-option>
                  <el-option label="方案" value="2"></el-option>
                  <el-option label="资讯" value="3"></el-option>
                  <el-option label="需求" value="4"></el-option>
                  <el-option label="产品" value="5"></el-option>
                </el-select>
                <el-button class="bindsousuo" slot="append" type="primary" icon="el-icon-search" @click="gotoSearch">搜索</el-button>
              </el-input>

              <el-dropdown style="position:absolute;right: 13%;bottom: 0;"  @command="handleCommand">
                <el-button class="bindsousuoyouxi" style="">
                
                免费发布
              
              </el-button>
                          <el-dropdown-menu slot="dropdown" class="lang-select-dropdown">
                              <el-dropdown-item command="a" style="width:60px;text-align:center">发布图文</el-dropdown-item>
                              <el-dropdown-item command="b" style="width:60px;text-align:center">发布需求</el-dropdown-item>
                  
                          </el-dropdown-menu>
              </el-dropdown>

 
              
           
        </div>






          <!-- 在这个地方加一个导航栏 -->

          <div class="flexwidth">

            <router-link active-class="titleorange" class="divvv" v-for="item in titlelist" :key="item.title" @click='gotoevery(item.title)' :to="item.link">{{item.title}}</router-link>

          </div>
        </div>

      <el-carousel height="374px">
        <el-carousel-item>
          <img @click="qiyevip"  class="zoumadeng" :src="require('../assets/qiyevip.jpg')" alt="解析失败" /> 
          <!-- <el-button type="primary">123</el-button>        -->
        </el-carousel-item>
        <el-carousel-item>
          <img @click="gerenvip" class="zoumadeng" :src="require('../assets/gerenvip.jpg')" alt="解析失败" />        
        </el-carousel-item>
      </el-carousel>
  
  
<!--       
      <div class="gonggao"><i class="iconfont icon-tongzhigonggao"></i><span class="green">公告：</span><span class="blue">重要通知，生态网页版上线啦~</span></div>
      <div class="zhong">

        <div class="tuijianshangji">
          <div class="title">推荐方案</div>
          <div class="more" @click="Toscheme">更多</div>
        </div>
  
  
        <div class='zongyinying'>
          <div class="yinying" v-for="item in imginfo" :key="item.id" @click="todetailtuijianfangan(item.id)">
            <input class="yinyingimage" type="image" :src="item.imgBase" alt="">
            <div class="title">{{item.title}}</div>
            <div class="othertitle">{{item.tags}}</div>
            <div class="company">{{item.nickname}}</div>
          </div>
  
        </div>

  
        <div class="tuijianshangji">
          <div class="title">推荐需求</div>
          <div class="more" @click="ToResource">更多</div>
        </div>
  
        <div class="shangjidetail" v-for="item in needlist" :key="item.id" @click="Toxuqiudetail(item.id)">
          <div class="flex-between">
            <div class="title"><span class="shangyered">商</span>{{item.title}}</div>
            <div class="othertitle"><i class="xuqiusvg iconfont icon-dengdai" style="color: orange;"></i>等待对接</div>
          </div>
        </div>
        
   
  

  
        <div class="tuijianshangji">
          <div class="title">已入驻企业</div>
          <div class="more" @click="companyku">更多</div>
        </div>
  
        <div class="shangjidetail">
          <div class="flex-between" v-for="item in companylist" :key="item.id">
            <div class="title"><i :class="item.vip_Lv >= 2 ? 'iconfont icon-huiyuan1' : 'iconfont icon-huiyuan'"></i>{{item.name}}</div>
            <div class="othertitle" @click="Tolookcompany(item.id)">立即查看</div>
          </div>
        </div>
  
  
        <div class="tuijianshangji">
          <div class="title">推荐产品</div>
          <div class="more" @click="ToProduct">更多</div>
        </div>
  
  
        <div class='zongyinying'>
          <div class="yinying" v-for="item in goodsinfo" :key="item.id" @click="Tochanpindetail(item.good_id)">
            <input class="yinyingimage" type="image" :src="item.img_url" alt="">
            <div class="title">{{item.good_name}}</div>
            <div class="gongzi">￥{{item.price}}</div>
          </div>
  
        </div>
  
   -->

    <router-view></router-view>
  
  
  
  
      <!-- </div> -->
      
      <div class="wei">
        <div class="left">
          <!-- <dl>
            <dt>联系我们</dt>
            <dd><i class="iconfont icon-youxiang" style="color:orange"></i>123456@cn.com</dd>
            <dd><i class="iconfont icon-telephone"></i>1234569999</dd>
            <dd><i class="iconfont icon-mn_qiye_fill"></i>中易云（河北）信息技术有限公司</dd>
            <dd><i class="iconfont icon-gongsijianjie1"></i>易云生态是中易云（河北）信息技术有限公司旗下的物联网生态平台，致力于解决物联网行业各种问题汇聚行业资源，行业机会等，帮助企业宣传推广。</dd>
          </dl> -->
          <div class="leftone">联系我们</div>
          <div class="lefttwo"><i class="iconfont icon-youxiang" style="color:orange"></i>zeiot@zeiot.cn</div>
          <div class="leftthree"><i class="iconfont icon-telephone"></i>4001-800-987/0315-5065608</div>
          <div  class="leftfour"><i class="iconfont icon-mn_qiye_fill"></i>中易云（河北）信息技术有限公司</div>
          <div  class="leftfive"><i class="iconfont icon-gongsijianjie1"></i>易云生态是中易云（河北）信息技术有限公司旗下的物联网生态平台，致力于解决物联网行业各种问题汇聚行业资源，行业机会等，帮助企业宣传推广。</div>
          <div  class="leftfive"><i style="color: transparent;" class="iconfont icon-gongsijianjie1"></i><a style="color: #FFFEFF;text-decoration: none;" href="https://beian.miit.gov.cn/"  target="_blank"  rel="external nofollow">冀ICP备2021001431号-2</a></div>
          
          
        </div>
        <div class="zhongimg">
          <img :src="require('../assets/gongzhonghao.jpg')" alt="">
          <div>微信公众号</div>
        </div>
        <div class="right">
          <img :src="require('../assets/xiaochengxu.jpg')" alt="">
          <div>微信小程序</div>
        </div>
        <!-- <div class="right">易云生态是中易云（河北）信息技术有限公司旗下的物联网生态平台，致力于解决物联网行业各种问题汇聚行业资源，行业机会等，帮助企业宣传推广。</div> -->
      </div>
      <div class="footer-right">
        <!-- <div class="footerbg">
          <i class="iconfont icon-qianbao"></i>
          <div>商机</div>
        </div>
        <div class="footerbg">
          <i class="iconfont icon-jiejuefangan"></i>
          <div>方案</div>
        </div>
        <div class="footerbg">
          <i class="iconfont icon-ziyuan"></i>
          <div>资源</div>
        </div> -->
        <!-- <div class="footerbg" @click="toxiaoxi">
          <i class="iconfont icon-xiaoxi"></i>
          <div>消息</div>
        </div> -->
        <div class="footerbg" @click="tokefu">
          <i class="iconfont icon-denglu"></i>
          <div>客服</div>
        </div>
        <div class="footerbg" @click="toTop" v-if="top">
          <i class="iconfont icon-zhiding"></i>
          <div>置顶</div>
        </div>
      </div>
      <MyDialog ref="mycom"></MyDialog>
      <MyDialogkefu ref="kefudialog"></MyDialogkefu>
    </div>
  </template>
  
  <style lang="scss" scoped>
  #app {
    min-height: 2000px;
    min-width: 1000px !important;
  }
    // .el-select .el-input {
    //   width: 130px;
    // }
    // .input-with-select .el-input-group__prepend {
    //   background-color: #fff;
    // }
  // #app {
  //   font-family: Avenir, Helvetica, Arial, sans-serif;
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  //   text-align: center;
  //   color: #2c3e50;
  // }
  .tou {
    position: relative;
      background: linear-gradient(rgb(94,97,210),rgb(145,149,246));
      margin: 0 auto;
      width: 100%;
      // height: 100px;
      padding-bottom: 10px;
      .logo {
        padding-top: 15px;
        text-align: center;
        // display: inline-block;
        // margin: 0 auto;
        width: 80px;
        height: 80px;
        // position: relative;
        // left: 50%;
        // margin-left: -30px;
        position: absolute;
        left:15%;
        top: 40%;

      }
      .shuqian {
        color: #FFFEFC;
        position: absolute;
        right: 12%;
        top: 30px;
        font-size: 16px;
        cursor: pointer;
        z-index: 10;
        .xiaochengxu {
          margin-right: 15px;
          i{
            font-size: 12px;
          }
          .xiaochengxuhou {
            margin-left: 5px;
          }
        }
        .denglu {
          margin-right: 15px;
          i{
            font-size: 12px;
          }
          .dengluhou {
            margin-left: 5px;
          }
        }
        .mianfeifabu {
          margin-right: 15px;
          i{
            font-size: 12px;
          }
          .mianfeifabuhou {
            margin-left: 5px;
          }
        }
      }
    // ::v-deep {
    .inputofelementui {
      width: 36%;
      // width: 50% !important;
      position: relative;
      left: 50%;
      margin-left: -28%;
      .elselect {
        width:100px;background-color: #FFFFFF;
        border-radius: 5px 0 0 5px;
        color: black;
      }
      ::v-deep {
        .el-input-group__append {
          border: none;
          background-color: #8387ED;
        }
      }
      .bindsousuo {
        background-color: #FFB658;
        color: #FFFAF3;
        border-radius: 0 5px 5px 0;
        box-sizing: border-box;
        border: 1px solid transparent;
      }
    // }
  }
      .el-input-group__append {
              border: none;
       }
  
  }
  .bindsousuoyouxi {
        background-color: #FFB658;
        color: #FFFAF3;
        border-radius: 5px 5px 5px 5px;
        box-sizing: border-box;
        border: 1px solid transparent;
      }
  .gonggao {
      font-size: 16px;
      // width: 60%;
      margin-left: 15%;
      margin-top: 20px;
      .green {
        margin-left: 10px;
        color: #7ECB2D;
        font-size: 18px;
        font-weight: 600;
      }
      .blue {
        color: rgb(91,95,209);
        font-size: 18px;
        font-weight: 600;
        display: inline-block;
        margin-left: 5px;
      }
  }
  .tuijianshangji {
    position: relative;
    margin-top: 30px;
    margin-bottom: 20px;

    .title {
      text-align: center;
      font-size: 25px;
      font-weight: 600;
    }
    .more {
      position: absolute;
      top: 2px;
      right: 0;
      color: #5C60D1;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
  
    }
  }
  .flex-between {
    margin-top: 25px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
  }
  .flex-between::after {
      content: '';
          position: absolute;
          left: -1%;
          // right: 0;
          bottom: -8px;
          width: 102%;
          height: 2px;
          // border-radius: 6rpx;
          border-bottom: 2px solid #f0f0f0;
          box-sizing: border-box;
  }
  .shangjidetail {
    // width: 80%;
    margin: 0 auto;
    margin-top: 25px;
    cursor: pointer;
    i {
      margin-right: 5px;
    }
    .title {
      font-size: 18px;
  
    }
    .othertitle {
      color: #FEB85E;
      font-size: 18px;
    }
  
  }
  .zhong {
    // background-color: black;
    margin: 0 auto;
    width: 65%;
    // height: 20px;
    .flex-five {
      // margin: 0 auto;
      margin-top: 20px;
      display: flex;
      .flex-item {
        flex: 0 0 20%;
        text-align: center;
      }
    }
  
  }
  
  .zongyinying  {
    // overflow: hidden;
    margin-top: 20px;
    // width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap
  }
  .yinying {
    border-radius: 10px;
    width: 20px;
    // height: 20px;
    // background-color: #5C60D1;
    flex: 0 0 24%;
    box-shadow: 3px 3px 2px #ccc;
    // .  <div class="title">智慧大数据展示平台</div>
    //         <div class="othertitle">大数据</div>
    //         <div class="company">中
      margin-top: 20px;
      cursor: pointer;
    .yinyingimage {
      width: 100%;
      height: 190px;
      border-radius: 10px 10px 0 0;
    }
    .title {
      margin-left: 10px;
      font-size: 20px;
      padding-top: 10px;
      height: 50px;
    }
    .othertitle {
      color: #5C61D1;
      font-size: 15px;
      margin-left: 10px;
      padding-top: 5px;
      padding-bottom: 50px;
    }
    .company {
      text-align: center;
      padding-bottom: 8px;
      font-size: 15px;
    }
    .gongzi {
      color: #D15C5D;
      margin-left: 10px;
      padding-top: 5px;
      font-size: 18px;
  
    }
  }
  .xuqiusvg {
    color: #FFB659 !important;
  }
  
  
  
  
  .wei {
    margin-top: 50px;
    background-color: #2D3236;
    height: 400px;
    width: 100%;
    display: flex;
    .left {
      // display: flex;
      color: #FFFEFF;
      width: 14%;
      margin-left: 25%;
      margin-top: 100px;
      i {
        margin-right: 10px;
      }
      
      .leftone {
        display: flex;
        font-size: 20px;
        padding-bottom: 10px;
      }
      .lefttwo{
        font-size: 15px;
        padding-bottom: 5px;
      }
      .leftthree {
        font-size: 15px;
        padding-bottom: 5px;
      }
      .leftfour {
        display: flex;
        font-size: 15px;
        padding-bottom: 5px;
      }
      .leftfive {
        display: flex;
        font-size: 15px;
      }
    }
    .zhongimg {
      color: white;
      margin-left: 300px;
      margin-top: 125px;
      img {
  
        width: 150px;
        height: 150px;
        display: inline-block;
      }
      div {
        text-align: center;
        margin-top: 10px;
      }
    }
    .right {
      color: white;
      margin-left: 90px;
      margin-top: 125px;
      img {
        width: 150px;
        height: 150px;
        display: inline-block;
    }
    div {
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  // ::v-deep {
  //   i .icon-a-chengjiantubiaoku_bangong12 {
  //     font-size: 36px;
  //   }
  // }
  .wodemingpian {
    font-size: 50px !important;  
  }
  .topofwodemingpian {
    margin-top: 10px;
  }
  .shangyered {
    display: inline-block;
    border: 1px solid red;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    font-size: 12px;
    color: red;
    font-weight: 600;
    text-align: center;
    align-items: center;
    margin-right: 5px;
    position: relative;
    bottom: 1px;
  }
  ::v-deep {
    .inputofelementui  {
      width: 36%;
      // width: 50% !important;
      position: relative;
      left: 50%;
      margin-left: -18%;
    }
  }
  
  .footer-right {
    position: fixed;
    //top: 50%;
    //margin-top: -120px;
    bottom:20%;
    right: 0;
    z-index: 9999;
    width: 55px;
  
    .footerbg {
      cursor: pointer;
      background: #5D60D1;
      text-align: center;
      margin-bottom: 10px;
      height: 60px;
      padding: 5px;
      color: #FFFFFF;
      border-radius: 8px;
      opacity: 0.9;
      i{
        font-size: 32px;
      }
      div {
        padding-top: 5px;
      }
  
    }
  }
  .flexwidth {
        position:relative;
        right: 4px;
        display: flex;
        margin: 0 auto;
        width: 80%;
        justify-content: space-between;
        align-items:center;
        margin-top: 45px;
        color: #FFFFFF;

        .divvv {
          text-align: center;
          width: 100%;
          font-size: 20px;
          font-weight: 600;
          cursor: pointer;
          color: #fff;
          text-decoration: none;
      }
        div {
            text-align: center;
            width: 100%;
            font-size: 20px;
            font-weight: 600;
            cursor: pointer;
        }
        .titleorange {
            color: #FDB658;
            position: relative;
        }
        .titleorange::after {
            color: #FDB658;
            content: '';
            position: absolute;
            bottom: -10px;
            left: 50%;
            margin-left: -38px;
            width: 76px;
            height: 2px;
            border-bottom: 4px solid #FDB658;
            box-sizing: border-box;
        }

    }
  
  
    .zoumadeng {
      // max-width: 100%;
      // max-height: 100%;
      width:100%;
      cursor: pointer;
    }
    // .toulogo {
    //   display: flex;
    // }
  </style>
  <script>
  import MyDialog from '@/components/modal-dialog.vue'
  import MyDialogkefu from '@/components/modal-dialog-kefu.vue'
  export default {
      beforeRouteEnter(to,form,next) {
        console.log(to,form,'路由进来之前 首先进行拦截')
        next()
      },
      components: {
        MyDialog,
        MyDialogkefu
      },
      created() {
        this.wxlogin()
      },
      mounted() {
        // this.wxlogin()
        this.GetArticles()
        this.Getgoodlist()
        this.Getneedlist()
        this.GetCompanyList()
        //搞一个滚动监听  监听页面滚动 从而为了显示置顶做准备
        window.addEventListener('scroll', this.handleScroll)
        // this.getmyself()
      },
      data() {
        return {
          input1: '',
          input2: '',
          input3: '',
          select: '4',
          imginfo:[],
          goodsinfo:[],
          // 需求列表
          needlist:[],
          companylist:[],
          input:'',
          // 这个东西是设置的不显示
          top: false,
          titlelist: [
                {title:'首页',choose:false,link:'/home'},
                {title:'资源库',choose:false,link:'/resource'},
                {title:'方案库',choose:false,link:'/scheme'},
                {title:'资讯库',choose:false,link:'/information'},
                {title:'需求库',choose:false,link:'/need'},
                {title:'产品库',choose:false,link:'/product'},
                {title:'企业',choose:false,link:'/company'},
                {title:'会员中心',choose:false,link:'/vipcenter'},
            ],
          sysuser:JSON.parse(localStorage.getItem('sysuser'))
  
        }
      },
      methods: {
        //获取推荐方案
        GetArticles() {
          this.$store.dispatch('article/GetList',{
            'is_fangan': true,
            pageIndex:1,
            pageSize:8
          }
          ).then(res => {
            // console.log(res.data.Code,789)
            if(res.data.Code == 200) {
              this.imginfo = []
              // this.imginfo.push(res.data.Data[0])
              // this.imginfo.push(res.data.Data[1])
              // this.imginfo.push(res.data.Data[2])
              // this.imginfo.push(res.data.Data[3])
              this.imginfo = res.data.Data
              // console.log(this.imginfo,4564,'方案')
            }
          })
        },
        // 获取推荐产品
        Getgoodlist() {
          this.$store.dispatch('recommender/MiniGetListHome',{
              PageIndex:1,
              PageSize:8
          }).then(res => {
            // console.log(res,'看一下推荐产品')
            if(res.data.Code == 200) {
              // this.goodsinfo = []
              // this.goodsinfo.push(res.data.Data[0])
              // this.goodsinfo.push(res.data.Data[1])
              // this.goodsinfo.push(res.data.Data[2])
              // this.imginfo = res.data.Data
              // console.log(this.goodsinfo,4564)
              this.goodsinfo = res.data.Data


              // https://zeiot.vip/images/static/   对图片进行一个处理
              this.goodsinfo.forEach(item => {
                this.$set(item,'img_url','https://zeiot.vip/images/static/'+item.img_url)
              })
              // console.log(this.goodsinfo,456789)
            }
          })
        },
        // 获取需求列表
        Getneedlist() {
          this.$store.dispatch('need/GetList',{
            is_opportunity: -1,
            pageIndex:1,
            pageSize:6
          }).then(res => {
            // console.log(res.data,200,'需求列表')
            if(res.data.Code == 200) {
              // this.needlist = []
              // this.needlist.push(res.data.Data[0])
              // this.needlist.push(res.data.Data[1])
              // this.needlist.push(res.data.Data[2])
              // this.needlist.push(res.data.Data[3])
              // this.needlist.push(res.data.Data[4])
              // this.needlist.push(res.data.Data[5])
              // console.log(this.needlist,66686896,'需求列表')
              this.needlist = res.data.Data
            }
            // console.log(res,66686896)
          })  
        },
        // 获取公司列表
        GetCompanyList() {
          this.$store.dispatch('company/GetList').then(res => {
            console.log(res,'看一看公司的列表')
            if(res.data.Code == 200) {
              this.companylist = []
              console.log(res.data.Data,66686896)
              this.companylist.push(res.data.Data[0])
              this.companylist.push(res.data.Data[1])
              this.companylist.push(res.data.Data[2])
              this.companylist.push(res.data.Data[4])
              this.companylist.push(res.data.Data[5])
              this.companylist.push(res.data.Data[6])
            }
            // companylist
          })
        },
        // 获取页面滚动距离
        handleScroll() {
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
          // console.log(scrollTop,'我在滚我在滚')
          if(scrollTop > 200) {
            this.top = true
          } else {
            this.top = false
          }
        },
        // 实现有动画的置顶
        toTop() {
          let Top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
          // 设置计时器，实现滚动效果
          const timeTop = setInterval(() => {
          document.body.scrollTop = document.documentElement.scrollTop = Top -= 50
          if (Top <= 10) {
          clearInterval(timeTop)
          }
          }, 20)
        },
        // 点击搜索进行下一个页面的一个跳转
        gotoSearch() {
          // console.log('页面的跳转',this.select,this.input)
          // 把search值存到session里面
          sessionStorage.setItem('search',this.input)
  
          if(this.select == "4") {
            this.$router.push({name:'needpage'})
          }
          if(this.select == "1") {
            this.$router.push({name:'resourcepage'})
          }
          if(this.select == "2") {
            this.$router.push({name:'schemepage'})
          }
          if(this.select == "3") {
            this.$router.push({name:'informationpage'})
          }
          if(this.select == "5") {
            this.$router.push({name:'productpage'})
          }
        },
        ToProduct() {
          this.$router.push({name:'productpage'})
        },
        ToResource() {
          this.$router.push({name:'needpage'})
        },
        ToInformation() {
          this.$router.push({name:'informationpage'})
        },
        Toscheme() {
          this.$router.push({name:'schemepage'})
        },
        Toresourceku() {
          this.$router.push({name:'resourcepage'})
        },
        companyku() {
          this.$router.push({name:'companypage'})
        },
        Toxuqiudetail(e) {
          this.$router.push({name:'needdetail',params:{id:e}})
        },
        Tolookcompany(e) {
          this.$router.push({name:'conmpanydetail',params:{id:e}})
        },
        Tochanpindetail(e) {
          // console.log(e)
          this.$router.push({name:'productdetail',params:{id:e}})
        },
        ToLogin() {
          // sysuser ? sysuser.auth_persional.tel : '登录'
          if(this.sysuser && this.sysuser.auth_persional.tel) {
            this.$router.push({name:'gerenguanli'})

          }else {
            this.$router.push({name:'Login'})

          }



          // this.$router.push({name:'Login'})
        },
        Tophoto() {
          this.$refs.mycom.visible = true
        },
        todetailtuijianfangan(e) {
          this.$router.push({name:'schemedetail',params:{id:e}})
        },
        wxlogin() {
          console.log(this.$route,'搞微信登陆111new')

          this.getmyself()
        //   console.log(this.$router,'搞微信登陆222')
        //   console.log('对code暴力拿取',window.location.href)
          if(window.location.href.indexOf('code') >= 0){
                let code = window.location.href.split('?')[1];
                code = code.substring(5, code.indexOf('&'));
                console.log(code,'看一下现在拿到的code对不对')
                // window.location.href = window.location.origin + window.location.pathname

                this.$store.dispatch('admin/wxWebLogin', {
                  code:code,
                  state:''
                }).then(res => {
                  if(res.data.Code == 200) {
                    console.log(res.data.Msg,'登陆成功 在里面拿token',res)
                    localStorage.setItem('sysuser',JSON.stringify(res.data.Data))
                    this.sysuser = res.data.Data
                    localStorage.setItem('token',res.data.Msg)
                    this.token = res.data.Msg
                  }
                  window.location.href = window.location.origin + window.location.pathname


                  // window.location.href = 'http://www.zeiot.vip/#/home'
                  // window.location.href = 'http://www.zeiot.vip/'
                  // this.$router.replace({ query: {} });
                  // window.location.href = window.location.origin + window.location.pathname +'#/home'

                  // window.location.href = window.location.origin + window.location.pathname
                })

          }
        },
        tohuiyuancenter() {
          this.$router.push({name:'vipcenter'})

        },
        toxiaochengxu() {
          this.$refs.mycom.visible = true

        },
  
        tokefu() {
          console.log('把微信客服二维码放出来')
          this.$refs.kefudialog.visible = true

          // kefudialog
        },
        toxiaoxi() {

        },
        qiyevip() {
          // 这个是行业商机  项目合作的图
          // console.log(111)
          this.$router.push({name:'buypage'})

        },
        gerenvip() {
          // 这个是查看客户资源  行业商机的图
          this.$router.push({name:'vipcenter'})
        },
        tofabu() {
          if(!localStorage.getItem('token') || !localStorage.getItem('sysuser')) {
                  return this.$message.info('请先去登陆',localStorage.getItem('token'))
          }
          this.$router.push({name:'anewqiye'})

        },
        tologinout() {
          console.log(window.location)
          localStorage.clear()
          this.$message.info('注销成功')
          this.sysuser= ''
          this.token = ''
        },
        handleCommand(e) {
          if(!localStorage.getItem('token') || !localStorage.getItem('sysuser')) {
                  return this.$message.info('请先去登陆',localStorage.getItem('token'))
          }
          if(e == 'a') {
            // 这里填发布图文
            this.$router.push({name:'gerenfabu'}) 
          }else {
            // 这里填发布需求
            this.$router.push({name:'gerenfabuxuqiu'}) 
          }        
        },
        torenzheng() {
          console.log('111认证')

          // 去认证的话要加一个会员的一个限制
          if(this.sysuser.auth_company.examine_msg == '未审核') {
            this.$store.dispatch('wxuser/GetShow',{
              userid: this.sysuser.auth_company.user_id
            }).then(res => {
              console.log(res.data.Data,'拿出来企业身份')
              if(new Date(res.data.Data.vip_end_time) < new Date()) {
                this.$message.info('请先去充值299会员')
              }else {
                this.$router.push({name:'qiyerenzhengnew'}) 
              }

            })



          }else {
            if(this.sysuser.auth_company.examine_msg == '审核失败') {
              this.$store.dispatch('company/authcompanyreason').then(res => {
                console.log(res,'失败原因')
                this.$confirm(res.data.Msg,'审核失败，是否重新提交')
                .then(() => {
                  console.log(111,'确认')
                  this.$router.push({name:'qiyerenzhengnew'}) 

                })
                .catch(() => {
                  console.log(2222,'取消')
                })
              })

            }

          }
        },
        tomyqiye() {
          window.open('https://zeiot.vip/adminindex.html')
        },
        getmyself() {
          this.$store.dispatch('wxuser/GetMySelf').then(res => {
              console.log(res,'这个是这个页面获取的本人的消息')
              localStorage.setItem('sysuser',JSON.stringify(res.data.Data))
              // this.$router.push({name:'homepage'}) 
              // window.open('https://zeiot.vip/adminindex.html', '_bank')
              // sysuser:JSON.parse(localStorage.getItem('sysuser'))
              this.sysuser = res.data.Data
            })
        }
        

        


        

  

  
  
  
  
  
      }
  
  }
  
  </script>
  