import http from '../utils/http'
import api from '.'

export function getList(data) {
    return http.get(api.Good.miniGetList, data)
}
export function minigetInfo(data) {
    return http.get(api.Good.miniGetInfo + '/' + data.e)
}
export function getimgsbyid(data) {
    const id = data.e
    delete data.e
    return http.get(api.Good.GetImgsById + '/' + id, data) 
}