import {gotopay} from '../../api/vip'


export const state = {
    // aaa:66
}

export const actions = {
    Company_Pay(_,data) {
        return gotopay(data)
    },

}
export const mutations = {

}